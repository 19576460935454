import React, { useState } from "react";
import { Input, Button } from "reactstrap";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import FolderIcon from "@mui/icons-material/Folder"; 

// 🔹 Function to format timestamps (Convert seconds → "yyyy-mm-dd hh:mm")
const formatTimestamp = (name) => {
  if (/^\d{10}$/.test(name)) { // Check if it's a 10-digit timestamp
    const date = new Date(parseInt(name) * 1000); // Convert seconds to milliseconds
    return date.toISOString().slice(0, 16).replace("T", " "); // Format: "yyyy-mm-dd hh:mm"
  }
  return name; // If not a timestamp, return as is
};


const TreeNode = ({ name, node, path, handleSelectFile, handleSelectFolder, selectedFiles, folderMetadata, level = 0 }) => {
  const [expanded, setExpanded] = useState(false);
  const formattedName = formatTimestamp(name);

  // Check if all files inside the folder are selected
  const getAllFiles = (folder, basePath = path) => {
    let files = [];
    Object.keys(folder).forEach((key) => {
      // console.log("folder: ",folder);
      if (folder[key] === null) {
        files.push(`${basePath}/${key}`);
      } else {
        files = [...files, ...getAllFiles(folder[key], `${basePath}/${key}`)];
      }
    });
    return files;
  };

  // Function to get updated date from folderMetadata
  const getUpdatedDate = () => {
    // console.log(folderMetadata, path);
    if (folderMetadata && folderMetadata[path]) {
      return new Date(folderMetadata[path]).toLocaleString();
    }
    return "N/A";
  };


  const handleFileSelection = () => {
    console.log(`File URL: ${path}`); 
    handleSelectFile(path);
  };

  const allFilesInFolder = node !== null ? getAllFiles(node) : [];
  const fileCount = allFilesInFolder.length;

  const isFolderSelected = allFilesInFolder.length > 0 && allFilesInFolder.every((file) => selectedFiles.includes(file));

  const handleExpandToggle = () => {
    // Prevent toggling if already in the desired state
    setExpanded(!expanded);
  };

  return (
    
    <div style={{ textAlign: "left" }}>
      {/* Folder or File Entry */}
      <div style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
        {node !== null && (
          <Button
            color="link"
            onClick={() => setExpanded(handleExpandToggle)}
            style={{ padding: "0px", marginRight: "5px", fontSize: "18px", color: "black" }}
          >
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Button>
        )}

        {/* Checkbox for Files */}
        {node === null ? (
          <Input
            type="checkbox"
            checked={selectedFiles.includes(path)}
            onChange={handleFileSelection}
            style={{ marginRight: "5px" }}
          />
          ) : (
            // 🔹 Show Folder Checkbox ONLY when Expanded
            // expanded && (
            <Input
              type="checkbox"
              checked={isFolderSelected}
              onChange={() => handleSelectFolder(path, node)}
              sx={{
                    "& .MuiCheckbox-root": {
                      color: "#1976d2", // Blue color for unchecked state
                    },
                    "& .Mui-checked": {
                      color: "#1976d2", // Blue checkmark when checked
                    },
                    "& .MuiCheckbox-checkbox": {
                      backgroundColor: "#fafafa", // White background when checked
                    },
                    "&:hover": {
                      backgroundColor: "transparent", // Prevent background from changing on hover
                    },
              }}
            />
          // )
        )}

        <span>{node === null ? (
            <InsertDriveFileIcon sx={{ color: "#e7cb78fa", fontSize: 18 }} /> 
          ) : (
              <FolderIcon sx={{ color: "#e7cb78fa" }} /> 
          )}
          {" "} 
          {formattedName}
          {/* Display the number of files in the folder */}
          {node !== null && (
              <span style={{ marginLeft: "10px" }}>
                ({fileCount} {fileCount === 1 ? "file" : "files"})
              </span>
            )}
        </span>
       
        {level === 0 && (
        <span style={{ marginLeft: "10px" }}>
          <strong>Last Updated:</strong> {getUpdatedDate()}
        </span>
      )}
      </div>

      {/* Render Subitems if Expanded */}
      {expanded && node !== null && (
        <div style={{ marginLeft: "20px", borderLeft: "1px solid #ccc", paddingLeft: "10px" }}>
          {Object.entries(node).map(([key, childNode]) => (
            <TreeNode
              key={key}
              name={key}
              node={childNode}
              path={`${path}/${key}`}
              handleSelectFile={handleSelectFile}
              handleSelectFolder={handleSelectFolder}
              selectedFiles={selectedFiles}
              folderMetadata={folderMetadata} 
              level={level + 1}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default TreeNode;