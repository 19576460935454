import React, { useState, useEffect } from "react";
import Logo from "../dist/images/tc-logo-horizontal-gold.png";
import { Link } from "react-router-dom";
import ReorderIcon from '@mui/icons-material/Reorder';
import { MenuData } from "./MenuData";
import HomeIcon from '@mui/icons-material/Home';
import { getToken, resetUserSession } from "../service/AuthService"; 
import { useNavigate } from "react-router-dom";

import "../styles/font-awesome-4.7.0/css/font-awesome.min.css";
import "../styles/Navbar.css";

function Navbar() {
  const [openLinks, setOpenLinks] = useState(false);
  const [clicked, setClicked] = useState(false);
  const navigate = useNavigate();

  // Check if token exists to determine if user is logged in
  const token = getToken();
  const isLoggedIn = token !== null && token !== undefined && token !== 'undefined'; 
  
  const  handleClick = () => {
    setClicked(!clicked);
  }

  const toggleNavbar = () => {
    setOpenLinks(!openLinks);
  };

  const handleLogout = () => {
    resetUserSession(); 
    navigate("/");
  };

  

  return (

    <nav className="navbarItems">
      <h1>
         <i className="fa fa-tc"></i>
      </h1>
      <div className="menu-icons" onClick={handleClick}>
        <i className={clicked ? "fa fa-times" : "fa fa-bars"}></i>
      </div>
      <ul className={clicked ? "nav-menu active" : "nav-menu"}>
      {MenuData.map((item, index) => {
          // Only show "Sign Up" if the user is logged out
          if (item.title === "Sign up" && isLoggedIn) {
            return null; // Do not render "Sign up" if logged in
          }

          // Only show "Logout" if the user is logged in
          if (item.title === "Logout" && !isLoggedIn) {
            return null; // Do not render "Logout" if logged out
          }

          return (
            <li key={index}>
              <a
                href={item.url}
                className={item.cName}
                onClick={item.title === "Logout" ? handleLogout : null}  // Logout handler for "Logout" menu item
              >
                <i className={item.icon}></i> {item.title}
              </a>
            </li>
          );
        })}
      </ul>
    </nav>
  );
}
{/*
    <div className="navbar">
      <div className="leftSide" id={openLinks ? "open" : "close"}>
        <img src={Logo} alt="Logo"/> 
        <div className="hiddenLinks">
          <Link to="/" style={{ margin: "1rem" }}> Home </Link>
          <Link to="/Register"> Register </Link>
          <Link to="/Login"> Login </Link>
          <Link to="/Customer" style={{ margin: "1rem" }}> Dashboard </Link>
          <Link to="/ContactUs" style={{ margin: "1rem" }}> Contact </Link>
        </div>
      </div>
      <div className="rightSide">
        <Link to="/"> Home </Link>
        <Link to="/Register"> Register </Link>
        <Link to="/Login"> Login </Link>
        <Link to="/Customer"> Dashboard </Link>
        <Link to="/ContactUs"> Contact </Link>
        <button onClick={toggleNavbar}>
          <ReorderIcon />
        </button>
      </div>
    </div>
*/}

export default Navbar;