const buildDynamicFileBatches = (files, maxPayloadBytes = 4.5 * 1024 * 1024) => {
    const estimateFileSize = (file) => {
        return JSON.stringify({ name: file.name, type: file.type }).length + 2;
      };
    
      const batches = [];
      let currentBatch = [];
      let currentSize = 0;
    
      for (const file of files) {
        const size = estimateFileSize(file);
        if (currentSize + size > maxPayloadBytes) {
          batches.push(currentBatch);
          currentBatch = [];
          currentSize = 0;
        }
        currentBatch.push(file);
        currentSize += size;
      }
    
      if (currentBatch.length > 0) {
        batches.push(currentBatch);
      }
    
      return batches;
  };

  export default buildDynamicFileBatches;
  