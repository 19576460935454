import React, { useState, useEffect, useMemo } from "react";
import { Row, Col, Form, Button, Input, InputGroup, Card, CardBody, Spinner, Table } from "reactstrap";
import axios from "axios";
import ComponentCard from "./ComponentCard";
import '../../index.css'; 
import { useTable, useSortBy, usePagination } from 'react-table';
import '../../AppTable.css';

const authService = require("../../service/AuthService");

const  ModelManagement = () => {
  const user = authService.getUser();
  const organization = user.organization.toLowerCase();

  const [modelData, setModelData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [datasetList, setDatasetList] = useState([]);
  const [selectedDataset, setSelectedDataset] = useState("");

  const [formData, setFormData] = useState({
    num_classes: "",
    batch_size: "",
    num_steps: "",
    fine_tune_checkpoint: "",
    fine_tune_checkpoint_type: "",
    fine_tune_checkpoint_version: "",
    learning_rate_base: "",
    warmup_learning_rate: "",
    warmup_steps: "",
    momentum: "",
    classification_weight: "",
    localization_weight: "",
    gamma: "",
    alpha: "",
    metrics_set: "",
    eval_batch_size: "",
    feature_extractor: "",
    batch_norm_decay: "",
    batch_norm_epsilon: "",
    activation: "",
    bifpn_num_iterations: "",
    bifpn_num_filters: "",
    anchor_min_level: "",
    anchor_max_level: "",
    anchor_scale: "",
    aspect_ratios: "",
    scales_per_octave: ""
  });

  const modelManagementUrl = 'https://0w7jd4grfb.execute-api.us-east-1.amazonaws.com/prod/modelmanagement';
  const dataManagementUrl = 'https://0w7jd4grfb.execute-api.us-east-1.amazonaws.com/prod/datamanagement';
  const requestConfig = {
    headers: {
        'x-api-key': 'ujohgjsp2A9TzwOv6eGZp8EiGy71gXyx3w5Xkhhw'
    }
  }
  

  const fetchModelData = async () => {
    console.log(organization);
    try {
      const response = await axios.get(modelManagementUrl, {
        ...requestConfig,
        params: { organization }
      });
  
      const models = Array.isArray(response.data.models) ? response.data.models : [];
      // console.log("Extracted Model Data:", models);
      setModelData(models);  
      setLoading(false);
    } catch (error) {
      console.error("Error fetching model data:", error);
      setLoading(false);
    }
  };
  
  const fetchDatasets = async () => {
    try {
      const response = await axios.get(dataManagementUrl, {
        ...requestConfig,
        params: { organization }
      });
      // console.log(response.data.files);

      const uniqueDatasets = [...new Set(
        response.data.files.map(filePath => filePath.split("data/")[1]?.split("/")[0])
      )];

      setDatasetList(uniqueDatasets || []);
    } catch (e) {
      console.error("Failed to fetch dataset list", e);
    }
  };
  

  useEffect(() => {
    const initializePage = async () => {
      await fetchModelData();
      await fetchDatasets();
    };
  
    initializePage();
  }, []);
  

  const handleRebuildClick = (rowData) => {
    console.log("Clicked rebuild for:", rowData);
    setFormData({ ...rowData }); 
  };

  const handleDownloadClick = (rowData) => {
    console.log("Clicked download for:", rowData);
    setFormData({ ...rowData }); 
  };

  const data = useMemo(() => modelData, [modelData]);

  const columns = useMemo(() => [
    { Header: 'Model Name', accessor: 'modelName' },
    { Header: 'Num Classes', accessor: 'num_classes' },
    { Header: 'Train Batch Size', accessor: 'batch_size' },
    { Header: 'Num Steps', accessor: 'num_steps' },
    { Header: 'Learning Rate', accessor: 'learning_rate_base' },
    { Header: 'Warmup LR', accessor: 'warmup_learning_rate' },
    { Header: 'Warmup Steps', accessor: 'warmup_steps' },
    { Header: 'Momentum', accessor: 'momentum' },
    { Header: 'Gamma', accessor: 'gamma' },
    { Header: 'Alpha', accessor: 'alpha' },
    { Header: 'Cls Weight', accessor: 'classification_weight' },
    { Header: 'Loc Weight', accessor: 'localization_weight' },
    { Header: 'Eval Metrics', accessor: 'metrics_set' },
    { Header: 'Eval Batch Size', accessor: 'eval_batch_size' },
    { Header: 'Feature Extractor', accessor: 'feature_extractor' },
    { Header: 'Activation', accessor: 'activation' },
    { Header: 'BatchNorm Decay', accessor: 'batch_norm_decay' },
    { Header: 'BatchNorm Eps', accessor: 'batch_norm_epsilon' },
    { Header: 'BIFPN Iter', accessor: 'bifpn_num_iterations' },
    { Header: 'BIFPN Filters', accessor: 'bifpn_num_filters' },
    { Header: 'Anchor Min Level', accessor: 'anchor_min_level' },
    { Header: 'Anchor Max Level', accessor: 'anchor_max_level' },
    { Header: 'Anchor Scale', accessor: 'anchor_scale' },
    {
      Header: 'Aspect Ratios',
      accessor: row => Array.isArray(row.aspect_ratios) ? row.aspect_ratios.join(', ') : 'N/A',
      id: 'aspect_ratios'
    },
    { Header: 'Scales/Octave', accessor: 'scales_per_octave' },
    { Header: 'Rebuild', id: 'rebuild', Cell: ({ row }) => (
        <a href="#" onClick={(e) => { 
                                      e.preventDefault();
                                      handleRebuildClick(row.original);
                                    }}
          style={{ color: "#0d6efd", textDecoration: "underline", cursor: "pointer", fontSize: "14px" }} >Rebuild</a>
      )},
      { Header: 'Download', id: 'download', Cell: ({ row }) => (
        <a href="#" onClick={(e) => { 
                                      e.preventDefault();
                                      handleDownloadClick(row.original);
                                    }}
          style={{ color: "#0d6efd", textDecoration: "underline", cursor: "pointer", fontSize: "14px" }}> Download </a>
      )} 
  ], []);
  
  const hiddenFields = ["modelName", "path", "fine_tune_checkpoint", "fine_tune_checkpoint_type", "fine_tune_checkpoint_version"];
  const tableInstance = useTable({ columns, data }, useSortBy, usePagination);

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } = tableInstance;


  return (
    <div align="center" style={{ paddingTop: "0px" }}>
      <Row className="justify-content-center" style={{ margin: "0 auto", maxWidth: "1200px" }}>
        <Col sm="12" md="12">
          {/*  SECTION 1: Dataset Selection */}
          <ComponentCard>
            <div style={{  maxWidth: "800px", margin: "0 auto" , marginTop: "10px", marginBottom: "0px"}}>
              
              <h5>STEP 1: Select Existing Dataset</h5>
              <Form>
                <Row className="align-items-center">
                  <Col md="2">
                    {/* <label style={{ fontWeight: "bold" }}>Select Dataset</label> */}
                  </Col>
                  <Col md="8">
                    <Input type="select" value={selectedDataset} onChange={(e) => setSelectedDataset(e.target.value)}>
                      <option value="">-- Select --</option>
                      {datasetList.map((name, idx) => (
                        <option key={idx} value={name}>{name}</option>
                      ))}
                    </Input>
                  </Col>
                  <Col md="2"></Col>
                </Row>
              </Form>
            </div>
          </ComponentCard>

          {/* SECTION 2: Configure Model */}
          <ComponentCard>
            <div style={{ maxWidth: "1000px", margin: "0 auto", marginTop: "10px", marginBottom: "0px" }}>
              <h5>STEP 2: Configure Your Model</h5>
              {formData && (
                <div style={{ marginTop: "20px", backgroundColor: "#f9f9f9", borderRadius: "8px", padding: "20px" }}>
                  <Row>
                    {Object.keys(formData).map((key) =>
                      !hiddenFields.includes(key) ? (
                        <Col md="3" key={key} style={{ marginBottom: "15px" }}>
                          <label style={{ fontWeight: "bold", fontSize:"14px" }}>{key}</label>
                          <Input
                            type="text"
                            style={{ height: "30px", fontSize:"14px" }}
                            value={formData[key]}
                            onChange={(e) => setFormData({ ...formData, [key]: e.target.value })}
                          />
                        </Col>
                      ) : null
                    )}
                  </Row>
                  <Button
                    color="success"
                    onClick={() => {
                      console.log("Submit Rebuild", formData);
                      setFormData({
                        ...Object.fromEntries(Object.keys(formData).map(k => [k, ""]))
                      });
                    }}
                  >
                    Submit Rebuild
                  </Button>
                </div>
              )}
            </div>
          </ComponentCard>

          {/* SECTION 3: View Summary Table */}
          <ComponentCard>
            <Row className="justify-content-center" style={{ paddingTop: "10px" }}>
              <Col md="12">
                <h5 style={{ fontWeight: "bold", marginBottom: "20px" }}>STEP 3: View Model Summary & Rebuild from Existing</h5>
                {loading ? (
                  <Spinner color="primary" />
                ) : (
                  <div className="app-table-wrapper">
                    <table {...getTableProps()} className="app-table">
                      <thead>
                        {headerGroups.map(headerGroup => {
                          const headerGroupProps = headerGroup.getHeaderGroupProps();
                          const { key: headerKey, ...restHeaderGroupProps } = headerGroupProps;
                          return (
                            <tr key={headerKey} {...restHeaderGroupProps}>
                              {headerGroup.headers.map(column => {
                                const columnProps = column.getHeaderProps(column.getSortByToggleProps());
                                const { key: colKey, ...restColumnProps } = columnProps;
                                return (
                                  <th
                                    key={colKey}
                                    {...restColumnProps}
                                    className={column.id === "modelName" ? "app-sticky-col-header" : ""}
                                  >
                                    {column.render("Header")}
                                    <span>
                                      {column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
                                    </span>
                                  </th>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </thead>
                      <tbody {...getTableBodyProps()}>
                        {page.map(row => {
                          prepareRow(row);
                          const rowProps = row.getRowProps();
                          const { key: rowKey, ...restRowProps } = rowProps;
                          return (
                            <tr key={rowKey} {...restRowProps}>
                              {row.cells.map(cell => {
                                const cellProps = cell.getCellProps();
                                const { key: cellKey, ...restCellProps } = cellProps;
                                return (
                                  <td
                                    key={cellKey}
                                    {...restCellProps}
                                    className={cell.column.id === "modelName" ? "app-sticky-col" : ""}
                                  >
                                    {cell.render("Cell")}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
              </Col>
            </Row>
          </ComponentCard>
        </Col>
      </Row>
    </div>

  );
};

export default ModelManagement;
