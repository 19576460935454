import React, { useState } from "react";
import { Row, Col, Form, Button, Input, InputGroup, Card, CardBody} from "reactstrap";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import DataManagement from '../src/components/dashboard/DataManagement';
import ModelManagement from '../src/components/dashboard/ModelManagement';
import './styles/Customer.scss';
import './dist/css/style.min.css';
import '../src/index.css'

const ModelBuilder = () => {
    const [selectedSection, setSelectedSection] = useState("data");

    const handleTabClick = (section) => {
        setSelectedSection(section);
    };

    const renderSection = () => {
        switch (selectedSection) {
            case "model":
                return <ModelManagement />;
            case "data":
                return <DataManagement />;
            default:
                return null;
        }
    };

    return (
        <div className="customer-page" style={{ padding: '20px 0', marginTop: "10px" }}>
            <div
                style={{
                    maxWidth: '1200px',
                    margin: '0 auto',
                    padding: '20px',
                    boxSizing: 'border-box',
                    marginTop: '80px',
                }}
            >
                <Typography variant="h6" gutterBottom align="center">
                    Welcome to the <br /><strong>Model Builder Dashboard!</strong>
                </Typography>
                <Typography align="center">
                    <p style={{ fontSize: "14px", fontWeight: "normal" }}>
                        Start by managing your data for building a model, or train away using your existing datasets!
                    </p>
                </Typography>

                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: "12px"}}>
					<Box sx={{ display: 'flex', gap: 1 }}>
						<div
						className={`tab ${selectedSection === 'data' ? 'active-tab' : ''}`}
						onClick={() => handleTabClick('data')}
						>
						Data Management
						<br></br>
						<p style={{ display: "inline", fontSize: "13px", fontWeight:"normal" }}>
							Add a new dataset, or View and manage <br></br>existing datasets for your organization.
						</p>
						</div>
						<div
						className={`tab ${selectedSection === 'model' ? 'active-tab' : ''}`}
						onClick={() => handleTabClick('model')}
						>
						Model Management

						<br></br>
						<p style={{ display: "inline", fontSize: "13px", fontWeight:"normal" }}>
							Train a new model or manage and rebuild <br></br> existing models using available datasets.<br></br>
						</p>
						</div>
					</Box>
				</Box>

                <div>
                    {renderSection()}
                </div>
            </div>
        </div>
    );
};

export default ModelBuilder;