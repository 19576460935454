import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Form, Button, Input, InputGroup, Card, CardBody, Spinner, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";
import ComponentCard from "./ComponentCard";
import TreeNode from "./TreeNode";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import '../../index.css'; 
import buildDynamicFileBatches from "./batchBuilder";
import pLimit from 'p-limit';
import { flushSync } from 'react-dom';

const authService = require("../../service/AuthService");

const DataManagement = () => {
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [uploading, setIsUploading] = useState(false);  
  const [uploadProgress, setUploadProgress] = useState(0);
  const [folderTree, setFolderTree] = useState({});
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [previewContent, setPreviewContent] = useState("");
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [collapsedFolders, setCollapsedFolders] = useState({}); 
  const folderInputRef = useRef(null);
  const [fileKey, setFileKey] = useState("");
  const [inputKey, setInputKey] = useState(0); 
 
  const user = authService.getUser();
  const organization = user.organization.toLowerCase();
  const username = user.name.toLowerCase();
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const [deleteProgress, setDeleteProgress] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedPath, setSelectedPath] = useState("");
  const [folderNameToUpload, setFolderNameToUpload] = useState("");
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [folderMetadata, setFolderMetadata] = useState({});

  const uploadBatchSize = 100;

  const dataManagementUrl = 'https://0w7jd4grfb.execute-api.us-east-1.amazonaws.com/prod/datamanagement';
  const requestConfig = {
    headers: {
        'x-api-key': 'ujohgjsp2A9TzwOv6eGZp8EiGy71gXyx3w5Xkhhw'
    }
}

  useEffect(() => {
    fetchFiles();
  }, []);

  const startProgressSimulation = (setProgress, setIsInProgress) => {
    setProgress(0);
    setIsInProgress(true);

    let simulatedProgress = 0;
    const interval = setInterval(() => {
        simulatedProgress += 10;
        if (simulatedProgress < 95) {
            setProgress(simulatedProgress);
        }
    }, 500);

    return () => {
        clearInterval(interval);
        setProgress(100);
        setTimeout(() => {
            setIsInProgress(false);
            setProgress(0);
        }, 1000);
    };
  };

  // Fetch files from API
  const fetchFiles = async () => {
    try {
      if (!organization) {
        console.error("Error: Organization is undefined");
        return;
      }
      console.log("organization:", organization);
      // console.log("requestConfig", requestConfig);
      try{
        
        const response = await axios.get(dataManagementUrl, {
          ...requestConfig,
          params: { organization }
        });
        // console.log(response);
        // console.log(response.data.files);
        // console.log("Fetched folderMetadata:", response.data.folderMetadata);
        const metadataObject = (response.data.folderMetadata || []).reduce((acc, item) => {
          const segments = item.fileUrl.split('/');
          const lastFolder = segments[segments.length - 1]; // Only the last folder
          acc[lastFolder] = item.updatedttm;
          return acc;
        }, {});       
    
        setFolderMetadata(metadataObject);
        setFolderTree(buildFolderTree(response.data.files || []));
      }
      catch(error){
        console.error("Error in api call:", error);
      }
      
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  };

  // Convert flat file list into a nested folder structure
  const buildFolderTree = (filePaths) => {
    const tree = {};
    filePaths.forEach((path) => {
      const parts = path.split("/").slice(1); // Remove bucket name
      if (parts[0] === 'data') {
        parts.shift(); // Remove 'data' part if present
      }
      let current = tree;
      parts.forEach((part, index) => {
        if (!current[part]) {
          current[part] = index === parts.length - 1 ? null : {};
        }
        current = current[part];
      });
    });
    return tree;
  };


  // Handle file selection for preview
  const handleSelectFile = async (filePath) => {
    console.log("handleSelectFile received:", filePath); 
    setPreviewContent(""); 
    setLoadingPreview(true); 
    setFileKey("");
  
    if (!filePath) { 
      setPreviewContent("No preview available.");
      setFileKey("No file selected.");
      setLoadingPreview(false);
      return;
    }
  
    try {
      const cleanedPath = filePath.split("/").slice(1).join("/"); 
      const presigned = await generatePresignedUrls({
        files: [{ name: cleanedPath }],
        action: "preview",
        datasetName: filePath.split("/")[0], 
      });

      console.log("filePath: ", filePath.split("/")[0]);
      console.log("Axios response:", presigned.data);

      if (presigned && presigned.length > 0) {
        setPreviewContent(presigned[0].url);  // URL to show in <img> or <iframe>
        setFileKey(filePath);
      } else {
        setPreviewContent("No preview available.");
      }
    } catch (error) {
      console.error("Error fetching file content:", error);
      setPreviewContent("No preview available.");
    } finally {
      setLoadingPreview(false);
    }
  
    toggleSelection(filePath);
  };
  

  // Toggle file selection for deletion/download
  const toggleSelection = (filePath) => {
    setSelectedFiles((prevSelectedFiles) => {
        let updatedSelection;
        if (prevSelectedFiles.includes(filePath)) {
            updatedSelection = prevSelectedFiles.filter(file => file !== filePath); 
        } else {
            updatedSelection = [...prevSelectedFiles, filePath]; 
        }

        // If no files are selected, reset preview
        if (updatedSelection.length === 0) {
          setPreviewContent("No preview available.");
          setFileKey("No file selected.");
       }

        return updatedSelection;
    });
  };


  // Select/Deselect entire folder and its contents
  const toggleFolderSelection = (folderPath, node) => {
    const getAllFiles = (folder, path = folderPath) => {
      let files = [];
      Object.keys(folder).forEach((key) => {
        console.log(folder[key])
        if (folder[key] === null) {
          files.push(`${path}/${key}`);
        } else {
          files = [...files, ...getAllFiles(folder[key], `${path}/${key}`)];
        }
      });
      return files;
    };

    const allFilesInFolder = getAllFiles(node);
    const alreadySelected = allFilesInFolder.every((file) => selectedFiles.includes(file));

    setSelectedFiles((prevSelected) =>
      alreadySelected
        ? prevSelected.filter((file) => !allFilesInFolder.includes(file))
        : [...prevSelected, ...allFilesInFolder]
    );
  };

  // Toggle folder collapse state
  const toggleFolderCollapse = (folderPath) => {
    setCollapsedFolders((prev) => {
      // Create a new state object based on previous state
      const updated = { ...prev };
      
      // Toggle the collapse state for the specific folder
      updated[folderPath] = !updated[folderPath];
      
      return updated;
    });
  };
  

  const handleUploadMultiple = async (isAddMode, destinationFolder = "") => {
    if (!selectedFiles || selectedFiles.length === 0) {
      alert("Please select a folder to upload.");
      return;
    }
  
    console.log("isAddMode: ", isAddMode);
    console.log("destinationFolder: ", destinationFolder);
  
    setIsUploading(true);
    setUploadProgress(0);
  
    const successfulUploads = [];
    const failedUploads = [];
  
    const originalTopFolder = selectedFiles[0].webkitRelativePath.split("/")[0];
    const datasetFolderName = isAddMode ? destinationFolder : originalTopFolder;
    const batches = buildDynamicFileBatches(selectedFiles, 500);
    const limit = pLimit(10); // limit concurrent uploads per batch
  
    const uploadAction = isAddMode ? "add" : "upload";
    const totalFileCount = selectedFiles.length;
    let completedCount = 0;
  
    console.log("Total batches:", batches.length);
  
    for (const batch of batches) {
      const urls = await generatePresignedUrls({
        files: batch,
        action: uploadAction,
        datasetName: datasetFolderName,
        useFullRelativePath: true,
        isAddMode: isAddMode
      });
  
      console.log("Signed URLs received for batch:", batch.length);
  
      const uploadTasks = urls.map(urlObj =>
        limit(async () => {
          const file = batch.find(f => {
            const relative = f.webkitRelativePath || f.name;
            const cleaned = relative.split("/").slice(1).join("/");
            return cleaned === urlObj.filename;
          });
  
          try {
            if (!file) throw new Error("File not found for URL mapping");
  
            console.log("PUT to:", urlObj.url);
            console.log("Expected filename (key):", urlObj.filename);
            console.log("Uploading file:", file.webkitRelativePath);
  
            await axios.put(urlObj.url, file, {
              headers: {
                'Content-Type': urlObj.contentType
              }
            });
  
            successfulUploads.push(urlObj.filename);
          } catch (err) {
            console.error("Upload failed for:", urlObj.filename, err);
            failedUploads.push(urlObj.filename);
          } finally {
            
            completedCount++;
            const progress = Math.floor((completedCount / totalFileCount) * 100);
            setUploadProgress(progress);
          }
        })
      );
  
      await Promise.all(uploadTasks);
    }
  
    // Write metadata to DynamoDB via a backend call
    await axios.post(dataManagementUrl, {
      action: "writeUploadMetadata",
      organization,
      datasetName: datasetFolderName,
      username,
      successCount: successfulUploads.length,
      failureCount: failedUploads.length
    }, requestConfig);
  
    
    setUploadProgress(100);
    setTimeout(() => {
      setUploadProgress(0);
      setIsUploading(false);
    }, 1000);
  
    setIsRefreshing(true);
    await fetchFiles();
    setIsRefreshing(false);
  
    alert(`Upload Complete!\nSuccessful: ${successfulUploads.length}\nFailed: ${failedUploads.length}`);
  };
   
  
  // Reusable function to generate presigned URLs
  const generatePresignedUrls = async ({ files, action, datasetName, useFullRelativePath = false, isAddMode = false }) => {
    // console.log("generateSignedUrl action: ", action);
    // console.log("generateSignedUrl isAddMode: ", isAddMode);
    // console.log("generateSignedUrl datasetName: ", datasetName); 

    const payload = {
      action: "generatePresignedUrls",
      organization,
      datasetName,
      username,
      operation: action, // "upload", "download", "preview"
      files: files.map(f => {
        const relativePath = typeof f === 'string' ? f : (f.webkitRelativePath || f.name);
        const cleanedPath = relativePath.split("/").slice(1).join("/");
        return {
          name: useFullRelativePath
            ? `${cleanedPath}`
            : `${relativePath}`,
          type: f.type || 'application/octet-stream'
          
        };
        
      })
    };
  
    const { data } = await axios.post(dataManagementUrl, payload, requestConfig);
    return data;
  };  
  
  
  const handleDeleteSelectedFiles = async () => {
    if (!selectedFiles || selectedFiles.length === 0) {
        alert("Please select at least one file to delete.");
        return;
    }
    if (!organization) {
        console.error("Error: Organization is undefined");
        return;
    }

    if (!window.confirm("Are you sure you want to delete the selected files? This action cannot be undone.")) {
        return; 
    }

    const stopDeleteProgress = startProgressSimulation(setDeleteProgress, setIsDeleting);

    try {
        const response = await axios.post(dataManagementUrl, {
          action: "deleteFiles",
          selectedFiles: selectedFiles,
          organization: organization  
        }, requestConfig);

        stopDeleteProgress();
        setIsDeleting(false);

        // Remove deleted files from selectedFiles & refresh file list
        flushSync(() => {
          setIsRefreshing(true);
        });
        await fetchFiles();
        setIsRefreshing(false);

    } catch (error) {
        stopDeleteProgress();
        console.error("Error deleting files:", error);
        alert("An error occurred while deleting files.");
    } finally {
        stopDeleteProgress();
    }
  };


  // Handle Download
  const handleDownloadFiles = async (selectedFiles) => {
    if (!selectedFiles || selectedFiles.length === 0) return;
  
    setIsDownloading(true);
    startProgressSimulation(setDownloadProgress, setIsDownloading);
  
    let parentFolder = '';
    if (selectedFiles.length > 0) {
      const path = typeof selectedFiles[0] === 'string'
        ? selectedFiles[0]
        : selectedFiles[0].webkitRelativePath || selectedFiles[0].name;
  
      const pathParts = path.split('/');
      parentFolder = pathParts.length > 1 ? pathParts[pathParts.length - 2] : '';
    }
  
    try {
      const presignedUrls = await axios.post(dataManagementUrl, {
        action: "download",
        organization,
        parentFolder,
        username,
        files: selectedFiles
      }, requestConfig);
  
      const downloadList = presignedUrls.data;
  
      const BATCH_SIZE = 3;
  
      for (let i = 0; i < downloadList.length; i += BATCH_SIZE) {
        const batch = downloadList.slice(i, i + BATCH_SIZE);
  
        for (const { url, filename } of batch) {
          const res = await fetch(url);
          const blob = await res.blob();
          const blobUrl = window.URL.createObjectURL(blob);
  
          // 🧠 Delay a little between clicks to ensure browser handles them properly
          setTimeout(() => {
            const a = document.createElement('a');
            a.href = blobUrl;
            a.download = filename.split('/').pop();
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(blobUrl);
          }, 100);
          
          // 💤 Short pause between downloads
          await new Promise(resolve => setTimeout(resolve, 300));
        }
  
        // Optional pause between batches
        await new Promise(resolve => setTimeout(resolve, 500));
      }
    } catch (err) {
      console.error("Error downloading files:", err?.response?.data || err.message);
      alert("Download failed.");
    } finally {
      setIsDownloading(false);
    }
  };
  

  const handleAddData = async(folderPath) => {
    setSelectedPath(folderPath);
    setModalOpen(true);
  };

  const toggleModal = () => setModalOpen(!modalOpen);


  const handleFolderChange = (event, source) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(files);
    
    const folderPath = event.target.files[0].webkitRelativePath;
    const selectedFolder = folderPath.split('/')[0];  // Extract the folder name from the file path

    if (!selectedFolder) return;

    if (source === "mainUpload") {
      // Check if folder already exists in any node of the folderTree (including subfolders)
      if (isFolderExistsInTree(folderTree, selectedFolder)) {
        setFolderNameToUpload(selectedFolder);
        setShowWarningModal(true);  // Show warning modal if folder exists
      }
    }
    // else if (source === "addData") {
    //   if (!isFolderExistsInTree(folderTree, selectedFolder)) {
    //     setFolderNameToUpload(selectedFolder);
    //     setShowWarningModal(true);  // Show warning modal if folder exists
    //   }
    // }
        
  };


  // Function to check if the folder already exists in the folder tree
  const isFolderExistsInTree = (tree, folderName) => {
    // Traverse through the tree and check if the folder exists
    for (const [key, node] of Object.entries(tree)) {
      if (key === folderName) {
        return true;  // Found the folder
      }

      // Recursively check subfolders
      if (node && typeof node === 'object' && isFolderExistsInTree(node, folderName)) {
        return true;
      }
    }
    return false;
  };

  const handleCancelUpload = () => {
    setShowWarningModal(false);  
    handleClearSelection();
  };

  const handleClearSelection = () => {
    setInputKey(prevKey => prevKey + 1); 
    if (folderInputRef.current) {
      folderInputRef.current.value = "";
    }
    setSelectedFiles([]);
  };

  

  return (
     <div align="center" style={{ paddingTop: "0px" }}>
          <Row className="justify-content-center" style={{ margin: "0 auto", maxWidth: "1200px" }}>
            <Col sm="12" md="12">
              {/*  SECTION 1: Dataset Selection */}
              <ComponentCard>
                <div style={{  maxWidth: "500px", margin: "0 auto" , marginTop: "10px", marginBottom: "0px"}}>
              {/* Title */}
              <h4 style={{ fontWeight: "bold", marginBottom: "15px" }}>
                Upload Data for Model Building
              </h4>

              {/* Instructions */}
              <div style={{ fontSize: "0.9rem", marginBottom: "15px" , textAlign: "left"}}>
                <p>Please follow the steps below to upload a <code>NEW</code> dataset:</p>
                <ul style={{ paddingLeft: "18px" }}>
                  <li>
                    Select a dataset folder in an <code>unzipped</code> format.
                  </li>
                  <li>
                    The dataset folder must contain <code>separated individual class folders</code> — one for each class.
                  </li>
                  <li>
                    For example, to upload a <code>"GolfBalls"</code> dataset, your folder structure should look like: <code>GolfBalls / Class X, Class Y, Class Z</code>
                  </li>
                </ul>
              </div>

              {/* File Upload Form */}
              <Form style={{ marginBottom: "0" }}>
                <InputGroup style={{ marginBottom: "0" }}>
                  <Input
                    key={inputKey}
                    type="file"
                    directory=""
                    webkitdirectory=""
                    ref={folderInputRef}
                    onChange={(e) => handleFolderChange(e, "mainUpload")}
                  />
                  <Button
                    color="outline-secondary"
                    onClick={() => handleUploadMultiple(false)}
                    disabled={uploading}
                  >
                    {uploading ? "Uploading..." : "Upload"}
                  </Button>
                </InputGroup>
              </Form>
            </div>
          </ComponentCard>


          {/* Modal for Folder Overwrite Warning */}
          <Modal isOpen={showWarningModal} toggle={handleCancelUpload} className="custom-modal">
            <ModalHeader toggle={handleCancelUpload} className="modal-header-title">
              Dataset Already Exists
            </ModalHeader>
            <ModalBody>
              <p>You are trying to upload data with name <code>{folderNameToUpload}</code>, which is already in use. If you are indeed uploading new data, please rename your folder and upload to avoid overwriting the existing dataset with this name.</p>
              <p>If you are trying to add more data into an existing dataset, you should not use this window. Click on the + button next to the dataset you want to add to instead.</p>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={() => handleUploadMultiple(true)}>Continue</Button>
              <Button color="secondary" onClick={handleCancelUpload}>Cancel</Button>
            </ModalFooter>
          </Modal>

          

          {/* File Explorer with Persistent Preview Below */}
          <ComponentCard >
            <h4 style={{ fontWeight: "bold", marginBottom: "15px" }}>
              File Explorer
            </h4>
            {/* Delete & Download Buttons */}
            {(isDownloading || isDeleting || uploading) && (
              <div style={{ marginBottom: "15px" }}>

                {/* Operation Text */}
                <div style={{ fontWeight: "bold", fontSize: "14px", marginBottom: "5px" }}>
                  {isDownloading
                    ? "Downloading..."
                    : isDeleting
                    ? "Deleting..."
                    : "Uploading..."}
                </div>

                {/* Progress Bar Container */}
                <div className="progress-bar-container" style={{
                  background: "#e0e0e0",
                  borderRadius: "6px",
                  overflow: "hidden",
                  height: "20px",
                  position: "relative"
                }}>
                  
                  {/* Filled Progress Bar */}
                  <div className="progress-bar" style={{
                    background: "#4caf50", // green
                    width: `${
                      isDownloading
                        ? downloadProgress
                        : isDeleting
                        ? deleteProgress
                        : uploadProgress
                    }%`,
                    height: "100%",
                    transition: "width 0.3s ease-in-out"
                  }} />

                  {/* Centered Progress Text */}
                  <div style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontWeight: "bold",
                    color: "white"
                  }}>
                    {isDownloading
                      ? `${downloadProgress}%`
                      : isDeleting
                      ? `${deleteProgress}%`
                      : `${uploadProgress}%`}
                  </div>
                </div>
              </div>
            )}
            <Row  className="d-flex justify-content-center" style={{ paddingTop: "0px", marginBottom: "20px" }}>
              <Col sm="auto" md="auto" className="text-center">
                <Button 
                  className="danger-action-button"
                  onClick={handleDeleteSelectedFiles} 
                  style={{ marginLeft: "10px" }} 
                  disabled={selectedFiles.length === 0 || isDeleting || isDownloading}>
                  {isDeleting ? "Deleting..." : "Delete Selected Files"}
                </Button>
                </Col>
                <Col sm="auto" className="text-center">
                <Button 
                  className="submit-action-button"
                  onClick={() => handleDownloadFiles(selectedFiles)} 
                  disabled={selectedFiles.length === 0 || isDownloading || isDeleting}>
                  {isDownloading ? "Downloading..." : "Download Selected Files"}
                </Button>
              </Col>
            </Row>
            
            <Row>
            {isRefreshing && <div><strong>Refreshing file list...</strong></div>}
              {/* File List Section */}
              <Col sm="8" md="8">
                <div style={{
                      fontSize: "13px",
                      height: "300px",        
                      overflowY: "auto",          
                      border: "1px solid #ddd",   
                      borderRadius: "6px",
                      padding: "10px",
                      backgroundColor: "#f9f9f9" 
                    }}>
                  {Object.entries(folderTree).map(([key, node]) => (
                    <div key={key} style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                    {/* Add Button */}
                    <div className="add-data-container">
                    <Button className="add-data-button" color="success" size="sm" style={{ padding: "2px 6px", fontSize: "10px" }} onClick={() => handleAddData(key)}>
                      <FontAwesomeIcon icon={faPlus} style={{ fontSize: "10px" }} />
                    </Button>
                    </div>
                    {/* Folder Name */}
                    <TreeNode
                      key={key}
                      name={key}
                      node={node}
                      path={key}
                      handleSelectFile={handleSelectFile}
                      handleSelectFolder={toggleFolderSelection}
                      handleSelectItem={toggleSelection}
                      handleToggleCollapse={toggleFolderCollapse}
                      collapsedFolders={collapsedFolders}
                      selectedFiles={selectedFiles}
                      folderMetadata={folderMetadata}
                    />
                    </div>
                  ))}
                </div>
              </Col>

              {/* 🔹 File Preview Section*/}
              <Col sm="4" md="4">
                <Card style={{ border: "1px solid #ddd", minHeight: "300px" }}>
                  <CardBody>
                    <h6>File Preview</h6>
                    {loadingPreview ? (
                      <Spinner color="primary" />
                    ) : previewContent?.startsWith("http") ? (
                      <>
                        {previewContent.match(/\.(jpg|jpeg|png|gif|bmp|webp)$/i) ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "250px",
                              width: "100%",
                              overflow: "hidden"
                            }}
                          >
                            <img
                              src={previewContent}
                              alt="Preview"
                              style={{
                                maxHeight: "100%",
                                maxWidth: "100%",
                                objectFit: "contain"
                              }}
                            />
                          </div>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "250px",
                              width: "100%",
                              overflow: "hidden"
                            }}
                          >
                            <iframe
                              src={previewContent}
                              title="File Preview"
                              style={{
                                width: "75%",
                                height: "90%",
                                border: "none"
                              }}
                            />
                          </div>
                        )}

                        <div
                          style={{
                            marginTop: "10px",
                            fontSize: "12px",
                            color: "#666",
                            wordBreak: "break-all"
                          }}
                        >
                          <strong>File Key:</strong> {fileKey}
                        </div>
                      </>
                    ) : (
                      <pre
                        style={{
                          whiteSpace: "pre-wrap",
                          wordWrap: "break-word",
                          maxHeight: "250px",
                          overflowY: "auto"
                        }}
                      >
                        {previewContent || "Select a file to preview."}
                      </pre>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/* Modal for Adding Data */}
            <Modal isOpen={modalOpen} toggle={toggleModal} className="custom-modal">
              <ModalHeader toggle={toggleModal} className="modal-header-title">
                Add New Item</ModalHeader>
              <ModalBody>
                <p>You're adding an item to directory:  <strong>{selectedPath}</strong></p>
                <p><strong>NOTE:</strong></p>
                <p>1. Select a dataset folder in an <code>unzipped</code> format for upload.</p>
                <p>2. Upload <mark><strong>only the new data</strong></mark> to avoid running into upload costs and failures.</p>
                <p>3. Any files already present in the destination will be overwritten and new ones will be added to the existing data.</p>
                {/* File Upload Section */}
                <Form>
                  <InputGroup style={{ maxWidth: "400px" }}>
                      <Input key={inputKey}
                      type="file"
                      directory=""
                      webkitdirectory=""
                      ref={folderInputRef}
                      onChange={(e) => handleFolderChange(e, "addData")}
                    />
                    <Button color="outline-secondary" onClick={() => handleUploadMultiple(true, selectedPath)} disabled={uploading}>
                      {uploading ? "Uploading..." : "Upload"}
                    </Button>
                  </InputGroup>
                </Form>
                {uploading && (
                  <div style={{ marginTop: "20px" }}>
                    {/* Operation Text */}
                    <div style={{ fontWeight: "bold", fontSize: "14px", marginBottom: "5px" }}>
                      Uploading...
                    </div>

                    {/* Progress Bar Container */}
                    <div style={{
                      background: "#e0e0e0",
                      borderRadius: "6px",
                      overflow: "hidden",
                      height: "20px",
                      position: "relative"
                    }}>
                      {/* Filled Progress Bar */}
                      <div style={{
                        background: "#4caf50",
                        width: `${uploadProgress}%`,
                        height: "100%",
                        transition: "width 0.3s ease-in-out"
                      }} />

                      {/* Centered Text */}
                      <div style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontWeight: "bold",
                        color: "white"
                      }}>
                        {uploadProgress}%
                      </div>
                    </div>
                  </div>
                )}

              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={toggleModal}>Cancel</Button>
              </ModalFooter>
            </Modal>
          </ComponentCard>
        </Col>
      </Row>
    </div>
  );
};

export default DataManagement;
