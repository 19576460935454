export const MenuData = [
    {
        title: "Home",
        url: "/",
        cName: "nav-links",
        icon: "fa fa-home"
    },
    {
        title: "Dashboard",
        url: "/Customer",
        cName: "nav-links",
        icon: "fa fa-table"
    },
    {
        title: "Contact",
        url: "/ContactUs",
        cName: "nav-links",
        icon: "fa fa-address-book"
    },
    {
        title: "Sign up",
        url: "/Register",
        cName: "nav-links-mobile"
    },
    {
        title: "Logout",
        url: "/",
        cName: "nav-links",
        icon: "fa fa-sign-out"
    },
]